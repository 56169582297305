import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import logo from "./images/FSIReversed.svg";
import Markdown from "markdown-to-jsx";

interface SearchableMarkdown {
  id: string;
  label: string;
  markdown: string;
  lowerMarkdown: string;
}

/**
 * The `sanitizedForId()` function sanitized a given string for use as an ID.
 * @param str The string to be sanitized.
 */
function sanitizeForId(str: string) {
  return str
    .replace(/#{1,6}\s*/, "")
    .replace(/[ÀÁÂÃÄÅàáâãäåæÆ]/g, "a")
    .replace(/[çÇ]/g, "c")
    .replace(/[ðÐ]/g, "d")
    .replace(/[ÈÉÊËéèêë]/g, "e")
    .replace(/[ÏïÎîÍíÌì]/g, "i")
    .replace(/[Ññ]/g, "n")
    .replace(/[øØœŒÕõÔôÓóÒò]/g, "o")
    .replace(/[ÜüÛûÚúÙù]/g, "u")
    .replace(/[ŸÿÝý]/g, "y")
    .replace(/[^a-z0-9- ]/gi, "")
    .replace(/ /gi, "-")
    .toLowerCase();
}

/**
 * The `convertMarkdownToArray()` function converts raw markdown data
 * (perferably) from a file into an array of searchable contents.
 * @param markdown Raw markdown data.
 */
function convertMarkdownToArray(markdown: string) {
  const arr: SearchableMarkdown[] = [];
  const markdownLines = markdown.split("\r\n");

  let h1 = "";
  let h2 = "";
  let m: SearchableMarkdown = {
    id: "",
    label: "",
    markdown: "",
    lowerMarkdown: "",
  };

  for (let i = 0; i < markdownLines.length; i++) {
    const line = markdownLines[i];
    const isHeader = /^#{1,3}\s/.test(line);
    const isH1 = /^#(?!#)/.test(line);
    const isH2 = /^##(?!#)/.test(line);
    const isH3 = /^###(?!#)/.test(line);

    if (isH1) {
      h1 = sanitizeForId(line);
    }

    if (isH2) {
      h2 = sanitizeForId(line);
    }

    if (isHeader) {
      // If the markdown item has an ID...
      if (m.id) {
        // Then push it to the array
        m.lowerMarkdown = m.markdown.toLowerCase();
        arr.push(m);
      }
      // Get the label...
      const label = line.replace(/#{1,6}\s*/, "");
      const sanitizedLabel = sanitizeForId(label);
      m = {
        id: (isH3 ? [h1, h2, sanitizedLabel] : [h1, sanitizedLabel]).join("-"),
        label,
        markdown: line + "\r\n",
        lowerMarkdown: "",
      };
    } else {
      m.markdown += line + "\r\n";
    }
  }
  arr.push(m);
  return arr;
}

/**
 * The `scrollToId()` function scrolls to a particular element with a given ID.
 * @param id The ID of the element to scroll to.
 */
function scrollToId(id: string) {
  const scroll = document.getElementById(id);

  if (scroll) {
    window.scrollTo({
      top: scroll.offsetTop,
      behavior: "smooth",
    });
  } else {
    console.warn(`An element could not be found with the ID ${id}.`);
  }
}

interface Navigable extends React.ComponentPropsWithoutRef<"a"> {
  heading: "h1" | "h2" | "h3";
  id: string;
}

const NavigableHeading = ({
  heading,
  id,
  children,
  className,
  ...props
}: Navigable) => {
  const Heading = heading;

  return (
    <Heading className="navigable" id={id}>
      {children}{" "}
      <a
        className={className ? `${className} anchor` : "anchor"}
        href={`#/#${id}`}
        title="Direct link to heading"
        {...props}
      >
        #
      </a>
    </Heading>
  );
};

const Home = () => {
  //creating searching functionality
  const [search, setSearch] = useState("");
  const [navigating, setNavigating] = useState(false);
  const [markdownArray, setMarkdownArray] = useState<SearchableMarkdown[]>([]);

  const file_name2 = "faq.md";

  useEffect(() => {
    import(`./${file_name2}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => {
            const convertedData = convertMarkdownToArray(res);
            setNavigating(true);
            setMarkdownArray(convertedData);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();
  //react-router navigate to the contact page
  const clickContact = () => {
    navigate("/contact-us");
  };

  // Responds to the hash route in the URL if one is being used to navigate
  // the home page
  useEffect(() => {
    if (!navigating) return;

    const split = window.location.hash.split("/");

    // If there is an additionally defined hash route aside from react-router
    if (split.length > 1) {
      const id = split.pop()?.replace("#", "");

      if (id) {
        scrollToId(id);
      }
    }

    setNavigating(false);
  }, [navigating]);

  const override = (id: string, heading: Navigable["heading"]) => ({
    component: NavigableHeading,
    props: {
      id,
      heading,
      // setTimeout 0 so the call is moved after the URL update in the stack
      onClick: () => setTimeout(() => setNavigating(true), 0),
    },
  });

  // faq + support page mapping + footer
  const FAQpage = (
    <div>
      <div className="wrapperFAQ" id="FAQRef">
        {markdownArray.map((m) => (
          <Markdown
            key={m.id}
            options={{
              overrides: {
                h1: override(m.id, "h1"),
                h2: override(m.id, "h2"),
                h3: override(m.id, "h3"),
              },
              slugify: () => m.id,
            }}
          >
            {m.markdown}
          </Markdown>
        ))}
      </div>

      <Footer />
    </div>
  );

  // main part of the home page
  return (
    <div>
      <div className="backgroundPage">
        <div className="header">
          <img src={logo} alt="Flightsafety Logo" className="fslogo" />
        </div>
        <div className="searchContent">
          <div className="searchBar">
            <h1 className="welcomeText">Welcome to Online Training Support</h1>
            <div className="input-container">
              <input
                className="searchInput"
                placeholder="&nbsp;&nbsp; &#x1F50D;  How Can We Help You?"
                type="text"
                value={search}
                onChange={(event) => {
                  const value = event.target.value;
                  setSearch(value);
                }}
              />
              <ul
                id="FAQsearchList"
                className={search ? "list" : "list.hiddenList"}
              >
                {search &&
                  markdownArray
                    .filter((m) => {
                      return m.lowerMarkdown.includes(search.toLowerCase());
                    })
                    .map((m) => (
                      <li
                        key={m.id}
                        onClick={() => {
                          scrollToId(m.id);
                        }}
                      >
                        {m.label}
                      </li>
                    ))}
              </ul>
            </div>
            <div className="searchQuestions"></div>
          </div>
          <div className="links">
            <div
              className="supportBtn"
              onClick={() => scrollToId("support-support")}
            >
              <p className="linkTitle">Support</p>
              <p className="extraText">
                Guides to solving common technical problems
              </p>
            </div>
            <div className="faqBtn" onClick={() => scrollToId("FAQRef")}>
              <p className="linkTitle">FAQ</p>
              <p className="extraText">Frequently Asked Questions</p>
            </div>
            <div className="contactBtn" onClick={clickContact}>
              <p className="linkTitle">Contact</p>
              <p className="extraText">
                Can't find the answer? <br /> Contact us for additional support.
              </p>
            </div>
          </div>
        </div>
      </div>
      {FAQpage}
    </div>
  );
};

export default Home;
