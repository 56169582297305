import React, { useEffect, useState } from "react";
import logo from "./images/FlightSafetyLogo-Reversed.png";
import scrollUp from "./images/scroll-up.png";

type Props = {};

const Footer = (props: Props) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [footerActive, setFooterActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", showFooter);
  }, []);

  const showFooter = () => {
    if (window.pageYOffset >= 100) {
      setFooterActive(true);
    } else {
      setFooterActive(false);
    }
  };

  return (
    <footer
      onClick={scrollToTop}
      className={footerActive ? "footer" : "footer.active"}
    >
      <img src={logo} className="logoFooter" alt="Flightsafety" />
      <p className="footerTxt">BACK TO TOP</p>
      <img src={scrollUp} alt="scroll" className="scrollBtn" />
    </footer>
  );
};

export default Footer;
