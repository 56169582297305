import React, { FormEvent, useRef, useState } from "react";
import logo from "./images/FlightSafetyLogo-Reversed.png";
import Required from "./Required";
import ReCaptchaV2, { ReCAPTCHA } from "react-google-recaptcha";
import {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC,
  REACT_APP_SEND_MAIL_ROUTE,
} from "./config";
import { verifyToken } from "./utils/verifyToken";
import {
  CountryCode,
  ProvinceCode,
  ProvinceName,
  StateCode,
  StateName,
} from "./types";
import { CA_PROVINCES, COUNTRIES, US_STATES } from "./constants";
import { getUnsuccessfulResponseMessage } from "./utils/getUnsuccessfulResponseMessage";

interface FormProperties {
  acknowledgement: boolean;
  birthDate: string;
  citizenshipCountry: CountryCode;
  city: string;
  company: string;
  companyStreetAddress: string;
  companyCity: string;
  companyStateProvince: StateName | ProvinceName | string;
  companyZipPostal: string;
  companyCountry: CountryCode;
  country: CountryCode;
  email: string;
  firstName: string;
  lastName: string;
  legalNames?: string;
  stateProvince: StateName | ProvinceName | string;
  streetAddress: string;
  zipPostal: string;
}

const Registration = () => {
  const [formState, setFormState] = useState<Partial<FormProperties>>({});
  const [formValidated, setFormValidated] = useState(false);

  function handleChange<K extends keyof FormProperties>(
    key: K,
    value: FormProperties[K]
  ) {
    setFormState({
      ...formState,
      [key]: value,
    });
  }

  // sets the button to disabled unless recaptcha is clicked
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);

  // Google Captcha V2
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    // Prevent further event propagation
    e.preventDefault();
    // Add submitted class to form element
    (e.target as HTMLFormElement).classList.add("submitted");

    const {
      acknowledgement,
      birthDate,
      citizenshipCountry,
      city,
      company,
      companyCity,
      companyCountry,
      companyStateProvince,
      companyStreetAddress,
      companyZipPostal,
      country,
      email,
      firstName,
      lastName,
      stateProvince,
      streetAddress,
      zipPostal,
      legalNames,
    } = formState as FormProperties;

    if (!acknowledgement) {
      console.warn(
        "Somehow the user was able to submit the page without having checked the checkbox."
      );
      return;
    }

    const response = await fetch(
      "https://privacyportal.onetrust.com/request/v1/consentreceipts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: formState.email,
          requestInformation:
            "eyJhbGciOiJSUzUxMiJ9.eyJwcm9jZXNzSWQiOiI5NGJkMTBjYi01NjUxLTQ5M2MtOTdkZS03ZmVhYWVjZTFlNTgiLCJwcm9jZXNzVmVyc2lvbiI6MSwiaWF0IjoiMjAxOC0wNi0wN1QxMTowMToyMi4wOSIsIm1vYyI6IkFQSSIsInN1YiI6IkVtYWlsIEFkZHJlc3MiLCJpc3MiOm51bGwsInRlbmFudElkIjoiODMzYWY1YmQtYjFmMy00MjNhLWE1OTEtOWQ5ZDQwZGUxMTdjIiwiZGVzY3JpcHRpb24iOiJDb25zZW50IHRvIE9ubGluZSBUcmFpbmluZyBDbGllbnQgQ29uc2VudCB2aWEgZUxlYXJuaW5nLmZsaWdodHNhZmV0eS5jb20iLCJjb25zZW50VHlwZSI6IkZPUk1TVUJNSVQiLCJwb2xpY3lfdXJpIjoiaHR0cHM6Ly93d3cuZmxpZ2h0c2FmZXR5LmNvbS9mc19wcml2YWN5LnBocCIsImFsbG93Tm90R2l2ZW5Db25zZW50cyI6ZmFsc2UsInB1cnBvc2VzIjpbeyJpZCI6IjY4M2EyODk4LWIyNGMtNDRhMi1hNjU0LTYwNzA2ZWFhZDdlMyIsInZlcnNpb24iOjEsInRvcGljcyI6W119XSwiZHNEYXRhRWxlbWVudHMiOltdfQ.KCHQfpNtDvOy7-96uqt7iXcRFIoghl78UCpMdKODFVzPOS20WAHdW2KkAbD7y94J0YLCJG9m9C3rOY3fHQirZLvOMODuGBqPD27c91prkkHd6rX1FdsOybkTuAlzMB5o3NosQQmlP1Y_KU-iU3tPHla0gC5hCo1uQDrWipdwE8zJf5J6eFdbjxQ93hs7pRLvVIDjJ6jAwoxszyu88XAkdPM3ms3ogWH3SJUYT3b28zhgf-EdQxq-4ZbluIniRw8FtFO6_oWNldnS3RZ6c62k0C8InFE3msE_5j8V0hoaNncKLyZFPGUTlMQI4xIyPfsj5gSOj-k6U96wuKxh_CW5_0_YaJZwii_ltW6tjPeWiykrk886ied1RrO-re80xl-BIRNMR7IDllU2PYKG38MAJaOC3d5GJqp8ErS0ZVkfflMYjC50kd8_7NqPTOC_aOZGvXw5cYwjfyRL2f3gcjf63VTMEbNANtUOD8Fr_AreDkeo6AIJXW-j2XMnft383XxGTygi2dJCI9e-PdabJHIEF2nwneCWt8E_uJXSwFutHCqZBm24RPin1N2n7zw5jcx4dzwQNPrn3SxeOf4H-co9aeGkPObAx5WTYbDQDft9L8iw93Mqh9r8obZXP5NiUhR2mN6v6E408nUyaoBdm7uaCa8EDW0iYrjI9Dw7YCngVA8",
          purposes: [
            {
              Id: "683a2898-b24c-44a2-a654-60706eaad7e3",
            },
          ],
          test: "true",
        }),
      }
    );

    if (response.status < 200 || response.status >= 300) {
      window.alert(
        "Your submission could not be completed at this time. OneTrust failed."
      );
      console.warn("OneTrust API request failed.");
      return;
    }

    const oneTrustSuccessTime = Date.now();

    if (recaptchaRef.current !== null) {
      const token = recaptchaRef.current.getValue();
      const tokenResponse = await verifyToken(token);
      if (tokenResponse && tokenResponse.success) {
        const submissionEmail = {
          subject: `New Client Registration -- ${firstName} + ${lastName}`,
          text: `Client Information\n    Legal First Name: ${firstName}\n    Legal Last Name: ${lastName}\n    Other Legal Names: ${
            legalNames ? legalNames : "Not provided"
          }\n    Country of Citizenship: ${
            COUNTRIES[citizenshipCountry]
          } ${citizenshipCountry}\n    Date of Birth: ${birthDate}\n    Email Address: ${email}\n    Street Address: ${streetAddress}\n    City: ${city}\n    State/Province: ${stateProvince}\n    Zip/Postal: ${zipPostal}\n    Country: ${
            COUNTRIES[country]
          } ${country}\n\nCompany Information\n    Company: ${company}\n    Street Address: ${companyStreetAddress}\n    City: ${companyCity}\n    State/Province: ${companyStateProvince}\n    Zip/Postal: ${companyZipPostal}\n    Country: ${
            COUNTRIES[companyCountry]
          } ${companyCountry}\n\nPDPS Client Consent\n\n    Acknowledged: ${new Date(
            oneTrustSuccessTime
          ).toLocaleString()}`,
        };

        const mailResponse = await fetch(REACT_APP_SEND_MAIL_ROUTE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionEmail),
        });

        window.alert("Your registration was successfully submitted.");
        window.location.reload();
      } else {
        const message = getUnsuccessfulResponseMessage(tokenResponse);
        window.alert(message);
      }
    }
  }

  return (
    <div className="backgroundPage backgroundRepeat">
      <div className="header">
        <img src={logo} alt="Flightsafety Logo" className="fslogo" />
      </div>
      <div className="wrapperForm">
        <h1>Register for Online Training</h1>
        <form
          className={formValidated ? "validated" : ""}
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend>Personal Information</legend>
            <div className="gridWrapper">
              <Required className="row1">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("firstName", value);
                  }}
                  placeholder="Legal First Name"
                  required
                  type="text"
                />
              </Required>
              <Required className="row1">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("lastName", value);
                  }}
                  placeholder="Legal Last Name"
                  required
                  type="text"
                />
              </Required>
              <input
                className="formInput row1"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange("legalNames", value);
                }}
                placeholder="Other Legal Names"
                type="text"
              />
              <Required className="row2">
                <select
                  className="formSelect"
                  onChange={(e) => {
                    const value = e.target.value as CountryCode;
                    handleChange("citizenshipCountry", value);
                  }}
                  required
                >
                  <option value="">Country of Citizenship</option>
                  {Object.keys(COUNTRIES).map((code) => (
                    <option key={`citizenship_country-${code}`} value={code}>
                      {COUNTRIES[code as CountryCode]}
                    </option>
                  ))}
                </select>
              </Required>
              <Required className="row2">
                <input
                  className="formInput"
                  onChange={(e) => {
                    // on change only happens after a valid date is entered
                    const value = e.target.value;
                    handleChange("birthDate", value);
                  }}
                  onFocus={(e) => {
                    if (e.target.type !== "date") e.target.type = "date";
                  }}
                  onBlur={(e) => {
                    // value is empty unless a valid date is entered!
                    if (!e.target.value) e.target.type = "string";
                  }}
                  placeholder="Date of Birth"
                  required
                />
              </Required>
              <Required className="row2">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("email", value);
                  }}
                  placeholder="Email Address"
                  required
                  type="email"
                />
              </Required>
              <Required className="row3 span2">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("streetAddress", value);
                  }}
                  placeholder="Street Address"
                  required
                  type="text"
                />
              </Required>
              <Required className="row3">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("city", value);
                  }}
                  placeholder="City"
                  required
                  type="text"
                />
              </Required>
              <Required className="row4">
                {formState.country === "CA" ? (
                  <select
                    className="formSelect"
                    onChange={(e) => {
                      const value = e.target.value as ProvinceCode;
                      handleChange("stateProvince", CA_PROVINCES[value]);
                    }}
                    required
                  >
                    <option value="">Select a Province</option>
                    {Object.keys(CA_PROVINCES).map((code) => (
                      <option key={`resident_province-${code}`} value={code}>
                        {CA_PROVINCES[code as ProvinceCode]}
                      </option>
                    ))}
                  </select>
                ) : formState.country === "US" ? (
                  <select
                    className="formSelect"
                    onChange={(e) => {
                      const value = e.target.value as StateCode;
                      handleChange("stateProvince", US_STATES[value]);
                    }}
                    required
                  >
                    <option value="">Select a State</option>
                    {Object.keys(US_STATES).map((code) => (
                      <option key={`resident_state-${code}`} value={code}>
                        {US_STATES[code as StateCode]}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="formInput"
                    onChange={(e) => {
                      const value = e.target.value;
                      handleChange("stateProvince", value);
                    }}
                    placeholder="State/Province"
                    required
                    type="text"
                  />
                )}
              </Required>
              <Required className="row4">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("zipPostal", value);
                  }}
                  placeholder="Zip/Postal"
                  required
                  type="text"
                />
              </Required>
              <Required className="row4">
                <select
                  className="formSelect"
                  onChange={(e) => {
                    const value = e.target.value as CountryCode;
                    handleChange("stateProvince", "");
                    handleChange("country", value);
                  }}
                  required
                >
                  <option value="">Country</option>
                  {Object.keys(COUNTRIES).map((code) => (
                    <option key={`address_country-${code}`} value={code}>
                      {COUNTRIES[code as CountryCode]}
                    </option>
                  ))}
                </select>
              </Required>
            </div>
          </fieldset>
          <fieldset>
            <legend>Company Information</legend>
            <div className="gridWrapper">
              <Required className="row1">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("company", value);
                  }}
                  placeholder="Company Name"
                  required
                  type="text"
                />
              </Required>
              <Required className="row2 span2">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("companyStreetAddress", value);
                  }}
                  placeholder="Street Address"
                  required
                  type="text"
                />
              </Required>
              <Required className="row2">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("companyCity", value);
                  }}
                  placeholder="City"
                  required
                  type="text"
                />
              </Required>
              <Required className="row3">
                {formState.country === "CA" ? (
                  <select
                    className="formSelect"
                    onChange={(e) => {
                      const value = e.target.value as ProvinceCode;
                      handleChange("companyStateProvince", CA_PROVINCES[value]);
                    }}
                    required
                  >
                    <option value="">Select a Province</option>
                    {Object.keys(CA_PROVINCES).map((code) => (
                      <option key={`resident_province-${code}`} value={code}>
                        {CA_PROVINCES[code as ProvinceCode]}
                      </option>
                    ))}
                  </select>
                ) : formState.country === "US" ? (
                  <select
                    className="formSelect"
                    onChange={(e) => {
                      const value = e.target.value as StateCode;
                      handleChange("companyStateProvince", US_STATES[value]);
                    }}
                    required
                  >
                    <option value="">Select a State</option>
                    {Object.keys(US_STATES).map((code) => (
                      <option key={`resident_state-${code}`} value={code}>
                        {US_STATES[code as StateCode]}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="formInput"
                    onChange={(e) => {
                      const value = e.target.value;
                      handleChange("companyStateProvince", value);
                    }}
                    placeholder="State/Province"
                    required
                    type="text"
                  />
                )}
              </Required>
              <Required className="row3">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("companyZipPostal", value);
                  }}
                  placeholder="Zip/Postal"
                  required
                  type="text"
                />
              </Required>
              <Required className="row3">
                <select
                  className="formSelect"
                  onChange={(e) => {
                    const value = e.target.value as CountryCode;
                    handleChange("companyStateProvince", "");
                    handleChange("companyCountry", value);
                  }}
                  required
                >
                  <option value="">Country</option>
                  {Object.keys(COUNTRIES).map((code) => (
                    <option key={`company_country-${code}`} value={code}>
                      {COUNTRIES[code as CountryCode]}
                    </option>
                  ))}
                </select>
              </Required>
            </div>
          </fieldset>
          <fieldset>
            <legend>Online Training Data Usage Consent</legend>
            <div className="consent-text">
              <p>
                As a valued client, this Client Consent to Process Personal
                Information informs you of the lawful bases by which
                FlightSafety (defined below) processes your personal information
                and requests your consent to conduct that processing. This
                consent is collected by FlightSafety International Inc., a U.S.-
                based company, which operates other FlightSafety affiliates and
                subsidiaries within the United States and around the world
                (collectively, “FlightSafety”). Your consent will cover
                FlightSafety and all of its affiliates and subsidiaries,
                wherever they are located. For additional information on
                FlightSafety’s privacy practices, please read our{" "}
                <a
                  href="https://www.flightsafety.com/privacy-statement/"
                  target="_blank"
                >
                  Privacy Statement
                </a>{" "}
                or contact our Data Protection Office at{" "}
                <a href="mailto:dataprotection@flightsafety.com">
                  dataprotection@flightsafety.com
                </a>
                .
              </p>
              <p>
                You have the choice to not sign this form, which will have the
                effect of declining consent to process your personal
                information. You may also revoke your consent at any time after
                it is provided. However, if you do not provide consent or later
                revoke it, FlightSafety may be unable to provide our services or
                training to you. FlightSafety may still, however, have the
                ability to process your personal information without your
                consent, as indicated by the lawful bases for processing
                described below and in our{" "}
                <a
                  href="https://www.flightsafety.com/privacy-statement/"
                  target="_blank"
                >
                  Privacy Statement
                </a>
                .
              </p>
              <h3>Information we collect and process</h3>
              <p>
                Based on the training type, location, or additional
                products/services requested, FlightSafety may collect and
                process the following information:
              </p>
              <table>
                <tr className="header-row consent">
                  <td>
                    <b>Personal Information</b>
                  </td>
                  <td>
                    <b>Business Purpose</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Contact information:</b>
                    Name, date of birth, nationality/citizenship, alias(es),
                    home address, phone number(s), email address, and emergency
                    contact name and contact information
                  </td>
                  <td>
                    <ul>
                      <li>
                        Training and/or services preparation, delivery, and
                        documentation
                      </li>
                      <li>Training enrollment, scheduling, and attendance</li>
                      <li>Marketing analysis and promotion </li>
                      <li>Visitor/guest badges </li>
                      <li>Internal and external audits </li>
                      <li>Emergency notification </li>
                      <li>
                        Assistance with entry visa/permit or U.S. Transportation
                        Security Administration (TSA) authorization (where
                        applicable){" "}
                      </li>
                      <li>
                        Restricted party screening and applicable trade
                        compliance obligations
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Education and skills: </b>
                    Education and training history, related performance
                    information, and language proficiency
                  </td>
                  <td>
                    <ul>
                      <li>Course prerequisite requirements </li>
                      <li>
                        Responding to your requests for information or support{" "}
                      </li>
                      <li>
                        Training and/or services preparation, delivery, and
                        documentation{" "}
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Employment information: </b>
                    Employment history, employers, job title/role
                  </td>
                  <td>
                    <ul>
                      <li>
                        Training and/or services preparation, delivery, and
                        documentation{" "}
                      </li>
                      <li>
                        Contractual OEM (original equipment manufacturer) course
                        attendance reporting requirements{" "}
                      </li>
                      <li>
                        Assistance with entry visa/permit or TSA authorization
                        (where applicable){" "}
                      </li>
                      <li>
                        Restricted party screening and applicable trade
                        compliance obligations{" "}
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements{" "}
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Government identifiers: </b>
                    Aviation certificate/license details, government-issued
                    identification (e.g., driver license, identity card,
                    passport), including photographs on those documents
                  </td>
                  <td>
                    <ul>
                      <li>Validate identity</li>
                      <li>Validate course prerequisites </li>
                      <li>Internal and external audits </li>
                      <li>
                        Assistance with entry visa/permit or TSA authorization
                        (where applicable){" "}
                      </li>
                      <li>
                        Restricted party screening and applicable trade
                        compliance obligations
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Financial or payment information: </b>
                    Financial account information, credit card information (may
                    be supplied by your employer if applicable)
                  </td>
                  <td>
                    <ul>
                      <li>Payment of TSA or visa-related fees </li>
                      <li>Securing hotel/car reservations, transportation </li>
                      <li>
                        Payment of fees for training or services provided{" "}
                      </li>
                      <li>
                        Making other approved payment arrangements on your
                        behalf{" "}
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Network-related, photo and video information:</b>
                    Activity while accessing FlightSafety systems or networks,
                    security video and photo identification when accessing
                    FlightSafety controlled areas
                  </td>
                  <td>
                    <ul>
                      <li>
                        Security of FlightSafety networks and information
                        systems{" "}
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requirements{" "}
                      </li>
                      <li>
                        Simulator training debriefing and live learning
                        assurance{" "}
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p>
                The above personal information may be shared internally at
                FlightSafety, for the purposes stated above and consistent with
                our Privacy Statement. FlightSafety collects and processes only
                the personal information needed to comply with applicable law,
                deliver our training and services, and as would be reasonably
                expected by clients.
              </p>
              <p>
                If you are protected by the General Data Protection Regulation
                (“GDPR”) or other similar data protection laws, the lawful bases
                on which FlightSafety collects and processes personal
                information are FlightSafety’s performance of a contract to
                provide training or services, compliance with legal obligations,
                and legitimate interests derived from your use of FlightSafety’s
                services. When the foregoing legal bases do not apply, then your
                consent permits FlightSafety to process your personal
                information consistent with this notice.
              </p>
              <h3>Transfers of personal information </h3>
              <p>
                FlightSafety may disclose or transfer your personal information
                to sub-processors or service providers, including across
                borders, on the lawful bases shared immediately above. Further,
                if your current or former employer requests personal information
                about you, FlightSafety may have a contractual obligation to
                provide it to your current or former employer. Your personal
                information may also be shared with governmental regulatory
                authorities due to legal requirements applicable to
                FlightSafety’s services.
              </p>
              <p>
                FlightSafety is headquartered in the United States and
                administrative activities necessary for our services are
                performed within the United States. Where permitted by
                applicable law, we may transfer personal data to the United
                States and other jurisdictions that are not deemed to provide
                the same level of data protection as the GDPR or similar laws.
                In the absence of an adequacy decision, by signing below, you
                consent to such transfers that are necessary for the performance
                of a contract. Cross-border transfers to sub-processors, service
                providers, or government regulators may include the following:
              </p>

              <table className="three-cols">
                <tr className="header-row consent">
                  <td>
                    <b>Personal Information</b>
                  </td>
                  <td>
                    <b>Business Purpose</b>
                  </td>
                  <td>
                    <b>Recipient and Location</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Contact information and government identifiers </b>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Restricted party screening and applicable trade
                        compliance obligations{" "}
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements{" "}
                      </li>
                      <li>CRM services </li>
                      <li>Scheduling, registration and record keeping </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Kreller Business Information Group, Inc. – U.S. </li>
                      <li>Regulatory DataCorp (RDC) – U.S. </li>
                      <li>Government regulators - country varies </li>
                      <li>SalesForce.com Inc. – U.S. </li>
                      <li>D.L.S. Systems LLC (Genesis) – U.S. </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Education and skills</b>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements{" "}
                      </li>
                      <li>
                        Contractual requirements with current or former
                        employer(s) of client{" "}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Government regulators - country varies</li>
                      <li>
                        Current/former employers of client– country varies
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Employment information</b>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Contractual OEM course attendance reporting requirements{" "}
                      </li>
                      <li>
                        Compliance with legal and/or regulatory requests or
                        requirements{" "}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>OEM location – country varies </li>
                      <li>Government regulators - country varies</li>
                    </ul>
                  </td>
                </tr>
              </table>

              <p className="rev">REVISION 2.0 </p>
            </div>
            <div className="consentWrapper">
              <input
                id="consent"
                className="formCheckbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  handleChange("acknowledgement", checked);
                }}
                required
                type="checkbox"
              />
              <label htmlFor="consent">
                I ACKNOWLEDGE AND AGREE TO THE PROCESSING OF MY PERSONAL
                INFORMATION IN ACCORDANCE WITH THE ABOVE
              </label>
            </div>
          </fieldset>

          <div className="captchaMargin">
            <ReCaptchaV2
              ref={recaptchaRef}
              sitekey={REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC}
              onChange={() => {
                setDisabledBtn(false);
              }}
            />
            <input
              className="submitButton"
              onClick={() => setFormValidated(true)}
              type="submit"
              value="Submit"
              disabled={disabledBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registration;
